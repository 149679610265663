// @flow
import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { SEO } from '../components/Layout/Seo'
import { Dashboard } from '../components/Layout/Dashboard'
import { Chart } from '../components/Accelerator/Chart'
import { BetaModal } from '../components/BetaModal'
import { isLoggedIn } from '../services/auth'
import type { Entity, Page } from '../types'

type Props = {
  location: {
    state: {
      showBetaMessage: bool,
    },
  },
  pageContext: {
    entity: Entity,
    page: Page,
  },
}

const Default = ({
  location,
  pageContext: {
    entity,
    page,
  },
}: Props) => {
  useEffect(() => {
    if (!isLoggedIn()) {
      navigate('/')
    }
  })

  const charts = page.charts.sort((a, b) => a.order - b.order)
  const leftColumn = charts.filter((chart, index) => !(index % 2))
  const rightColumn = charts.filter((chart, index) => index % 2)

  return (
    <Dashboard entity={entity}>
      {location.state && location.state.showBetaMessage && (
        <BetaModal entity={entity} />
      )}
      <SEO title={entity.name} />
      <div className="container-fluid">
        <h1 className="page-heading">{page.title}</h1>
        <div className="row">
          <div className="col-xl-8">
            {leftColumn.map(({ component_id }) => <Chart key={component_id} id={component_id} />)}
          </div>
          <div className="col-xl-4">
            {rightColumn.map(({ component_id }) => <Chart key={component_id} id={component_id} />)}
          </div>
        </div>
      </div>
    </Dashboard>
  )
}

export default Default
