// @flow
import { navigate } from 'gatsby'
import type { User } from '../types'

export const isBrowser = (): bool => typeof window !== 'undefined'

export const getUser = (): User => (
  isBrowser() && window.localStorage.getItem('gatsbyUser')
    ? JSON.parse(window.localStorage.getItem('gatsbyUser'))
    : {}
)

export const setUser = (user: User): void => window.localStorage.setItem('gatsbyUser', JSON.stringify(user))

export const isLoggedIn = (): bool => {
  const user = getUser()

  return !!user.username
}

export const logout = (callback?: (any) => any) => {
  setUser({})

  if (typeof callback === 'function') {
    callback()
  }

  navigate('/')
}
