// @flow
import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import type { Node } from 'react'
import { Layout } from './Layout'
import { Sidebar } from '../Sidebar'
import { Header } from './Header'
import { isLoggedIn } from '../../services/auth'
import type { Entity } from '../../types'

type Props = {
  entity: Entity,
  children: Node,
}

export const Dashboard = ({ entity, children }: Props) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false)
  const [isMobile, setMobile] = useState(false)

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate('/')
    }
  })

  useEffect(() => {
    setMobile(window.innerWidth < 768)
  }, [])

  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth >= 768 && isMobile) {
        setSidebarOpen(false)
        setMobile(false)
      } else if (window.innerWidth < 768 && !isMobile) {
        setMobile(true)
      }
    }

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [isMobile])

  return (
    <Layout>
      <Sidebar
        entity={entity}
        isMobile={isMobile}
        isOpen={isSidebarOpen}
        setOpen={setSidebarOpen}
      />
      <div className="content">
        <Header
          entity={entity}
          setSidebarOpen={setSidebarOpen}
        />
        <main className="main">{children}</main>
      </div>
    </Layout>
  )
}
