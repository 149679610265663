// @flow
import React, { useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Image } from '../Image'
import { SidebarItem } from './SidebarItem'
import { Form } from '../Accelerator/Form'
import LineChart from '../../svgs/line-chart.svg'
import MapPin from '../../svgs/map-pin.svg'
import SpeedometerGauge from '../../svgs/speedometer-gauge.svg'
import UsersGroup from '../../svgs/users-group.svg'
import TextLogo from '../../svgs/text-logo.svg'
import Question from '../../svgs/question.svg'
import type { Entity } from '../../types'

type Props = {
  entity: Entity,
  isMobile: bool,
  isOpen: bool,
  setOpen: (bool) => void,
}

const Icons = {
  SpeedometerGauge,
  LineChart,
  UsersGroup,
  MapPin,
}

export const Sidebar = ({
  entity,
  isOpen,
  setOpen,
  isMobile,
}: Props) => {
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  const renderModal = () => (
    <Modal isOpen={modal} toggle={toggle} className="feedback-modal">
      <ModalHeader toggle={toggle}>
        We&apos;re working on improvements
      </ModalHeader>
      <ModalBody className="text-center">
        <p>
          The team at PiqueData is working hard to make improvements and introduce new features.
          We would love to hear your thoughts and appreciate your feedback.
        </p>
        <Form id={entity.feedbackForm} />
      </ModalBody>
    </Modal>
  )

  const pagesSorted = entity.pages.sort((a, b) => a.order - b.order)

  const navItems = () => (
    <>
      <div className="sidebar-logo-wrapper p-0 bg-transparent border-0 shadow-none d-flex d-md-block">
        <div className="sidebar-logo">
          <Image filename="logo" />
        </div>
        <div className="sidebar-logo-text">
          <TextLogo />
        </div>
      </div>
      {pagesSorted.map((page) => {
        const Icon = Icons[page.icon]
        return (
          <SidebarItem
            key={page.title}
            name={page.title}
            action={page.url}
          >
            {Icon ? <Icon /> : <Question />}
          </SidebarItem>
        )
      })}
      {entity.feedbackForm && (
        <>
          <div className="sidebar-bottom-separator" />
          <SidebarItem
            name="Submit Feedback"
            action={() => setModal(true)}
          >
            <Question />
          </SidebarItem>
          {renderModal()}
        </>
      )}
    </>
  )

  if (isMobile) {
    return (
      <nav>
        <Menu
          isOpen={isOpen}
          onStateChange={(state) => setOpen(state.isOpen)}
          className="sidebar"
          width="auto"
          right
          customBurgerIcon={false}
        >
          {navItems()}
        </Menu>
      </nav>
    )
  }

  return (
    <nav className="sidebar">
      {navItems()}
    </nav>
  )
}
