// @flow
import React from 'react'
import { Link } from 'gatsby'
import { BurgerButton } from '../BurgerButton'
import { DropdownMenu } from '../DropdownMenu'
import { getUser, logout } from '../../services/auth'
import TextLogo from '../../svgs/text-logo.svg'

type Props = {
  setSidebarOpen: (bool) => void,
  entity: {
    name: string,
    url: string,
  }
}

export const Header = ({ setSidebarOpen, entity }: Props) => {
  const { username } = getUser()

  return (
    <header className="header">
      <Link className="header-logo" to="/" aria-label="Home">
        <TextLogo />
      </Link>
      <DropdownMenu
        label="ENTITY"
        option={entity.name}
      />
      <DropdownMenu
        label="USERNAME"
        option={username}
      >
        <button type="button" className="header-dropdown-item" onClick={logout}>Logout</button>
      </DropdownMenu>
      <BurgerButton
        onClick={() => setSidebarOpen(true)}
      />
    </header>
  )
}
