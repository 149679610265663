// @flow
import React, { useState, useEffect } from 'react'
import { v4 } from 'uuid'
import { Loader } from '../Loader'

type Props = {
  id: string,
}

export const BaseEmbed = ({ id }: Props) => {
  const [isReady, setIsReady] = useState(false)
  const uuid = `id-${v4()}`

  const embed = () => (
    _accEmbed({
      id,
      client: process.env.ACCELERATOR_DOMAIN || 'accelerator.docker',
      el: uuid,
      css: 'https://app.piquedata.com/embed.css',
    })
  )

  // Runs every time the component renders
  useEffect(() => {
    setIsReady(typeof window !== 'undefined')
  })

  // Runs whenever isReady changes
  useEffect(() => {
    if (isReady) {
      // If the accelerator script is already loaded
      if (typeof _accEmbed !== 'undefined') {
        embed()
      } else {
        const script = document.querySelector('#embedjs')

        if (script) {
          // Wait for the script to load
          script.addEventListener('load', () => {
            embed()
          }, false)
        }
      }
    }
  }, [isReady])

  return (
    <div className="embed-wrapper">
      <Loader />
      <div className="embed" id={uuid} />
    </div>
  )
}
