// @flow
import React from 'react'
import { BaseEmbed } from './BaseEmbed'

type Props = {
  id: string,
}

export const Chart = ({ id }: Props) => (
  <div className="chart card">
    <div className="card-body">
      <BaseEmbed id={id} />
    </div>
  </div>
)
