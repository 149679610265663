// @flow
import React from 'react'

type Props = {
  onClick: (any) => any,
}

export const BurgerButton = ({ onClick }: Props) => (
  <button
    type="button"
    aria-label="Open sidebar menu"
    className="header-burger-button"
    onClick={onClick}
  >
    <div />
    <div />
    <div />
  </button>
)
