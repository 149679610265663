// @flow
import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import type { Entity } from '../types'

type Props = {
  entity: Entity,
}

export const BetaModal = ({ entity }: Props) => {
  const [modal, setModal] = useState(true)
  const toggle = () => setModal(!modal)

  return (
    <Modal isOpen={modal} toggle={toggle} className="beta-modal">
      <ModalHeader toggle={toggle} />
      <ModalBody className="text-center">
        <p>
          {`Welcome to the beta dashboard for ${entity.name}`}.
        </p>
        <p>
          Here you&apos;ll find demographic resources to help visualise the state of the local
          economy, keeping in tune with the community, and providing accessible insights to assist
          when making data-informed decisions efficiently.
        </p>
        <p>
          Thank you for helping us to improve our app, to report a bug open the feedback form at
          the bottom of your screen. Updates and new features are released daily.
        </p>
        <p>
          Enjoy using PiqueData :&#41;
        </p>
      </ModalBody>
    </Modal>
  )
}
