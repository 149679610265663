// @flow
import React from 'react'
import { BaseEmbed } from './BaseEmbed'

type Props = {
  id: string,
}

export const Form = ({ id }: Props) => (
  <BaseEmbed id={id} />
)
