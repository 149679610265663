// @flow
import React, { useState, useEffect, useRef } from 'react'
import type { Node as ReactNode } from 'react'
import ChevronDown from '../svgs/chevron-down.svg'

type Props = {
  label: string,
  option: string,
  children?: ReactNode,
}

const defaultProps = {
  children: null,
}

export const DropdownMenu = ({ label, option, children }: Props) => {
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleClick = (e: Event) => {
      if (
        open
        && e.target instanceof Node
        && ref.current
        && !ref.current.contains(e.target)
      ) {
        setOpen(false)
      }
    }

    window.addEventListener('mousedown', handleClick)

    return () => {
      window.removeEventListener('mousedown', handleClick)
    }
  }, [open, ref])

  return (
    <div className="header-dropdown" ref={ref}>
      <div>
        <div className="header-dropdown-label">{label}</div>
        {children ? (
          <button type="button" className="header-dropdown-option" onClick={() => setOpen(true)}>
            <span>{option}</span>
            <div className="chevron">
              <ChevronDown />
            </div>
          </button>
        ) : (
          <div className="header-dropdown-option">
            <span>{option}</span>
          </div>
        )}
      </div>
      {open && (
        <div className="header-dropdown-menu">{children}</div>
      )}
    </div>
  )
}

DropdownMenu.defaultProps = defaultProps
