// @flow
import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Tooltip } from 'reactstrap'
import type { Node } from 'react'

type Props = {
  name: string,
  action: string | (any) => any,
  children: Node
}

export const SidebarItem = ({
  name,
  action,
  children,
}: Props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const id = `tooltip-${name.toLowerCase().replace(/ /g, '-')}`

  return (
    <div
      id={id}
      onMouseEnter={() => setTooltipOpen(true)}
      onFocus={() => setTooltipOpen(true)}
      onMouseLeave={() => setTooltipOpen(false)}
      onBlur={() => setTooltipOpen(false)}
    >
      {typeof action === 'string' && (
        <Link className="sidebar-item" activeClassName="sidebar-item-active" to={action} aria-label={name}>
          {children}
          <span className="sidebar-item-text">{name}</span>
        </Link>
      )}
      {typeof action === 'function' && (
        <button type="button" className="sidebar-item" onClick={action} aria-label={name}>
          {children}
          <span className="sidebar-item-text">{name}</span>
        </button>
      )}
      <Tooltip placement="right" isOpen={tooltipOpen} target={id}>
        {name}
      </Tooltip>
    </div>
  )
}
